import tw, { styled } from 'twin.macro'

const Section = styled.section`
  ${tw`relative`}
  ${props =>
    props.containsImages ? tw`my-6 md:my-8` : tw`my-12 md:my-16 relative`}
  ${props => (props.articleFirst ? tw`mt-6 md:mt-8!` : '')}
`

export default Section
