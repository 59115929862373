import React from 'react'
import { graphql } from 'gatsby'

import 'twin.macro'

import SEO from '../components/seo'
import PageContainer from '../components/page-container'
import ArticleIntro from '../components/article-intro'
import Text from '../components/text'
import PartnersListPrimary from '../components/partners-list-primary'
import PartnersListSecondary from '../components/partners-list-secondary'

const PartnersEntry = ({ data }) => {
  const { entry } = data

  return (
    <>
      <SEO data={entry.seomatic} />
      <PageContainer heading={entry.title}>
        <div tw="container">
          <h1 tw="text-5xl md:text-6xl text-center mb-16">{entry.title}</h1>
        </div>
        <article tw="pb-24">
          <ArticleIntro body={entry.intro}></ArticleIntro>
          <PartnersListPrimary partners={entry.partners} />
          <PartnersListSecondary
            noOfCols={1}
            noOfColsMd={3}
            headings={['Citizen Futurists']}
            partners={entry.partnersCitizenFuturists}
          />
          <PartnersListSecondary
            noOfCols={1}
            noOfColsMd={2}
            headings={['Website', 'Branding']}
            partners={entry.partnersCreative}
          />
          <div tw="container">
            <div tw="max-w-4xl mx-auto text-base md:text-lg lg:text-xl pt-16">
              <Text body={entry.additionalCredits}></Text>
            </div>
          </div>
        </article>
      </PageContainer>
    </>
  )
}

export default PartnersEntry

export const query = graphql`
  query {
    entry: craftPartnersPartnersEntry {
      seomatic {
        ...SEO
      }
      title
      intro
      partners {
        ...Partner
      }
      additionalCredits
      partnersCitizenFuturists {
        ...Partner
      }
      partnersCreative {
        ...Partner
      }
    }
  }
`
