import React from 'react'
import PropTypes from 'prop-types'
import 'twin.macro'

import ArticleHeading from './article-heading'
import Button from './button'

const PartnersListPrimary = ({ partners }) => {
  return partners.length ? (
    <div tw="container">
      <div tw="max-w-5xl mx-auto">
        {partners.map(partner => (
          <div tw="border-brand-blue border-t last:border-b">
            <div
              tw="w-full flex flex-wrap -mx-4 py-12 md:py-16"
              key={partner.remoteId}
            >
              <div tw="px-4 w-full lg:w-2/3 lg:mb-0">
                <ArticleHeading>{partner.title}</ArticleHeading>
                {partner.body && (
                  <div
                    tw="text-base md:text-lg lg:text-xl"
                    dangerouslySetInnerHTML={{
                      __html: partner.body,
                    }}
                  ></div>
                )}
                {partner.siteUrl && (
                  <Button
                    href={partner.siteUrl}
                    text="View website"
                    tw="mt-8"
                  ></Button>
                )}
              </div>
              {partner.thumbnailImage.length ? (
                <div tw="px-4 w-full lg:w-1/3 mt-12 md:mt-0">
                  <img
                    tw="w-full h-auto"
                    className="lazyload"
                    src={
                      partner.thumbnailImage[0].optimisedImages.placeholderBox
                    }
                    data-src={partner.thumbnailImage[0].optimisedImages.src}
                    data-srcset={
                      partner.thumbnailImage[0].optimisedImages.srcset
                    }
                    sizes="100vw"
                    alt={partner.thumbnailImage[0].altText}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null
}

PartnersListPrimary.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      remoteId: PropTypes.number.isRequired,
      title: PropTypes.string,
      body: PropTypes.string,
      siteUrl: PropTypes.string,
      thumbnailImage: PropTypes.array,
    })
  ),
}

PartnersListPrimary.defaultProps = {
  partners: [],
}

export default PartnersListPrimary
