import React from 'react'
import PropTypes from 'prop-types'

import 'twin.macro'

import Section from './section'

const ArticleIntro = ({ body }) =>
  body && (
    <Section articleFirst={true}>
      <div tw="container text-xl md:text-2xl lg:text-3xl">
        <div
          tw="max-w-4xl mx-auto"
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        ></div>
      </div>
    </Section>
  )

ArticleIntro.propTypes = {
  body: PropTypes.string,
}

ArticleIntro.defaultProps = {
  body: '',
}

export default ArticleIntro
