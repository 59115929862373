import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import tw, { styled } from 'twin.macro'

export const GridWrapper = styled.div`
  ${tw`flex flex-wrap`}

  ${({ columnGap }) => columnGap === 1 && tw`-mx-1`}
  ${({ columnGap }) => columnGap === 2 && tw`-mx-2`}
  ${({ columnGap }) => columnGap === 3 && tw`-mx-3`}
  ${({ columnGap }) => columnGap === 4 && tw`-mx-4`}
  ${({ columnGap }) => columnGap === 6 && tw`-mx-6`}
  ${({ columnGap }) => columnGap === 8 && tw`-mx-8`}
  ${({ columnGap }) => columnGap === 12 && tw`-mx-12`}
  ${({ columnGap }) => columnGap === 16 && tw`-mx-16`}
  ${({ columnGap }) => columnGap === 24 && tw`-mx-24`}
`

export const GridItem = styled(motion.div)`
  ${tw`w-full`}

  &:nth-of-type(n+1):nth-last-of-type(-n+1),
    &:nth-of-type(n+1):nth-last-of-type(-n+1)~& {
    ${tw`mb-0!`}
  }

  ${({ noOfCols }) => noOfCols === 2 && tw`w-1/2`}
  ${({ noOfCols }) => noOfCols === 3 && tw`w-1/3`}
  ${({ noOfCols }) => noOfCols === 4 && tw`w-1/4`}
  ${({ noOfCols }) => noOfCols === 5 && tw`w-1/5`}
  ${({ noOfCols }) => noOfCols === 6 && tw`w-1/6`}
  ${({ noOfColsMd }) => noOfColsMd === 2 && tw`md:w-1/2`}
  ${({ noOfColsMd }) => noOfColsMd === 3 && tw`md:w-1/3`}
  ${({ noOfColsMd }) => noOfColsMd === 4 && tw`md:w-1/4`}
  ${({ noOfColsMd }) => noOfColsMd === 5 && tw`md:w-1/5`}
  ${({ noOfColsMd }) => noOfColsMd === 6 && tw`md:w-1/6`}
  ${({ noOfColsLg }) => noOfColsLg === 2 && tw`lg:w-1/2`}
  ${({ noOfColsLg }) => noOfColsLg === 3 && tw`lg:w-1/3`}
  ${({ noOfColsLg }) => noOfColsLg === 4 && tw`lg:w-1/4`}
  ${({ noOfColsLg }) => noOfColsLg === 5 && tw`lg:w-1/5`}
  ${({ noOfColsLg }) => noOfColsLg === 6 && tw`lg:w-1/6`}
  ${({ noOfColsXl }) => noOfColsXl === 2 && tw`xl:w-1/2`}
  ${({ noOfColsXl }) => noOfColsXl === 3 && tw`xl:w-1/3`}
  ${({ noOfColsXl }) => noOfColsXl === 4 && tw`xl:w-1/4`}
  ${({ noOfColsXl }) => noOfColsXl === 5 && tw`xl:w-1/5`}
  ${({ noOfColsXl }) => noOfColsXl === 6 && tw`xl:w-1/6`}

  ${({ columnGap }) => columnGap === 1 && tw`px-1 mb-2`}
  ${({ columnGap }) => columnGap === 2 && tw`px-2 mb-4`}
  ${({ columnGap }) => columnGap === 3 && tw`px-3 mb-6`}
  ${({ columnGap }) => columnGap === 4 && tw`px-4 mb-8`}
  ${({ columnGap }) => columnGap === 6 && tw`px-6 mb-12`}
  ${({ columnGap }) => columnGap === 8 && tw`px-8 mb-16`}
  ${({ columnGap }) => columnGap === 12 && tw`px-12 mb-24`}
  ${({ columnGap }) => columnGap === 16 && tw`px-16 mb-32`}
  ${({ columnGap }) => columnGap === 24 && tw`px-24 mb-48`}

  ${({
    noOfCols,
  }) => `&:nth-of-type(${noOfCols}n+1):nth-last-of-type(-n+${noOfCols}),
      &:nth-of-type(${noOfCols}n+1):nth-last-of-type(-n+${noOfCols})~& {
        @media (max-width: 767px) {
          margin-bottom: 0 !important;
        }
      }`}

${({
    noOfColsMd,
  }) => `&:nth-of-type(${noOfColsMd}n+1):nth-last-of-type(-n+${noOfColsMd}),
      &:nth-of-type(${noOfColsMd}n+1):nth-last-of-type(-n+${noOfColsMd})~& {
        @media (min-width: 768px) and (max-width: 1023px) {
          margin-bottom: 0 !important;
        }
      }`}

${({
    noOfColsLg,
  }) => `&:nth-of-type(${noOfColsLg}n+1):nth-last-of-type(-n+${noOfColsLg}),
      &:nth-of-type(${noOfColsLg}n+1):nth-last-of-type(-n+${noOfColsLg})~& {
        @media (min-width: 1024px) and (max-width: 1279px) {
          margin-bottom: 0 !important;
        }
      }`}

${({
    noOfColsXl,
  }) => `&:nth-of-type(${noOfColsXl}n+1):nth-last-of-type(-n+${noOfColsXl}),
      &:nth-of-type(${noOfColsXl}n+1):nth-last-of-type(-n+${noOfColsXl})~& {
        @media (min-width: 1280px) {
          margin-bottom: 0 !important;
        }
      }`}
`

const variants = {
  initial: { opacity: 0, y: 40 },
  enter: i => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.05,
    },
  }),
  exit: {
    opacity: 0,
  },
}

const Grid = ({
  noOfCols,
  noOfColsMd,
  noOfColsLg,
  noOfColsXl,
  columnGap,
  children,
}) =>
  children.length && (
    <GridWrapper columnGap={columnGap}>
      {React.Children.map(children, (child, index) => (
        <GridItem
          key={child.props.id}
          custom={index}
          noOfCols={noOfCols}
          noOfColsMd={noOfColsMd || noOfCols}
          noOfColsLg={noOfColsLg || noOfColsMd || noOfCols}
          noOfColsXl={noOfColsXl || noOfColsLg || noOfColsMd || noOfCols}
          columnGap={columnGap}
          initial="initial"
          animate="enter"
          exit="exit"
          variants={variants}
        >
          {child}
        </GridItem>
      ))}
    </GridWrapper>
  )

Grid.propTypes = {
  noOfCols: PropTypes.number,
  noOfColsMd: PropTypes.number,
  noOfColsLg: PropTypes.number,
  noOfColsXl: PropTypes.number,
  columnGap: PropTypes.number,
  children: PropTypes.node,
}

Grid.defaultProps = {
  noOfCols: 1,
  noOfColsMd: null,
  noOfColsLg: null,
  noOfColsXl: null,
  columnGap: 4,
}

export default Grid
