import React from 'react'
import PropTypes from 'prop-types'
import 'twin.macro'

import ArticleHeading from './article-heading'
import Button from './button'
import Grid from './grid'

const PartnersListSecondary = ({
  partners,
  noOfCols,
  headings,
  noOfColsMd,
}) => {
  return partners.length ? (
    <div tw="container">
      <div tw="max-w-5xl mx-auto">
        <div tw="border-brand-blue border-b py-16">
          {headings.length === 1 ? (
            <ArticleHeading>Citizen Futurists</ArticleHeading>
          ) : null}
          <Grid
            noOfCols={noOfCols}
            noOfColsMd={noOfColsMd}
            noOfColsLg={noOfColsMd || noOfCols}
            noOfColsXl={noOfColsMd || noOfCols}
            columnGap={8}
          >
            {partners.map((partner, i) => (
              <div key={partner.remoteId}>
                {headings.length > 1 && headings[i] ? (
                  <ArticleHeading>{headings[i]}</ArticleHeading>
                ) : null}
                <h3 tw="text-sm text-brand-dark-grey mb-2">{partner.title}</h3>
                {partner.body && (
                  <div
                    tw="mb-4 md:mb-8 text-base"
                    dangerouslySetInnerHTML={{
                      __html: partner.body,
                    }}
                  ></div>
                )}
                {partner.siteUrl && (
                  <Button
                    small={true}
                    href={partner.siteUrl}
                    text="View website"
                  ></Button>
                )}
              </div>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  ) : null
}

PartnersListSecondary.propTypes = {
  headings: PropTypes.array,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      remoteId: PropTypes.number.isRequired,
      title: PropTypes.string,
      body: PropTypes.string,
      siteUrl: PropTypes.string,
      thumbnailImage: PropTypes.array,
    })
  ),
}

PartnersListSecondary.defaultProps = {
  headings: [],
  partners: [],
}

export default PartnersListSecondary
